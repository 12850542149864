import { InfoType, UserMunicipality } from "@shared/models/user-municipality.model";

export const SaltdalMunicipality: UserMunicipality = {
  name: 'saltdal',
  slogan: {value: 'Finn frem til aktivitet og frivillighet <br> - reklamefritt og gratis', id: 'banner.meraker', meaning: 'bannerSlogan', description: 'slogan for meråker'},
  data: {},
  bannerURL: 'https://ucarecdn.com/2ba87b6b-3300-434c-97db-0744555242bb/-/preview/2000x600/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 3,
      maxAge: 18,
      url: ''
    }
  ]
}
